.zoundslike_admin_words_crud_syn {
  width: 100%;
  margin: auto;
  /* height: 100vh; */
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.zoundslike_admin_words_buttons_syn {
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.zoundslike_admin_words_button_syn {
  width: 24%;
  text-align: center;
  background: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #000;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media only screen and (max-width: 400px){
    font-size: 12px;
  }
}

.zoundslike_admin_words_button_active_class_syn {
  width: 24%;
  text-align: center;
  background: #4ba3c3;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  @media only screen and (max-width: 400px){
    font-size: 12px;
  }
}

.zoundslike_word_container {
  width: 100%;
  margin: auto;
  /* background-color: rgba(247, 247, 248, 0.5); */
  padding: 20px;
  border-radius: 8px; /* Adjust the border radius as needed */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
}

.zoundslike_lang_dropdown_with_opt_synr {
  margin: 25px 0px;
}

.zoundslike_languages_dropdown {
  margin-bottom: 0px;
}

.zoundslike_language_options {
  margin-bottom: 0px;
  padding: 5px 6px;
}

.zoundslike_dropdown_value_and_icon {
  display: flex;
  justify-content: space-between;
  border: 1px solid;
  border-radius: 6px;
  padding: 0px 10px;
}

.zoundslike_lang_option_synr {
  width: 56.95%;
  position: absolute;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
  padding: 5px;
  z-index: 3;
  max-height: 215px;
  overflow-y: auto;
}

.zoundslike_language_options:hover {
  background-color: #dfdfdf61;
  margin-bottom: 0px;
  padding: 5px 6px;
}

/* ----------------------input text----------------------- */

.Zoundslike_word_input_synr {
  position: relative;
  margin: 5px 0px;
}
.Zoundslike_word_input_synr label {
  position: absolute;
  left: 0;
  padding-left: 10px;
  top: 2px;
  cursor: text;
}
.Zoundslike_word_input_synr input:focus + label,
.Zoundslike_word_input_synr input:not(:placeholder-shown) + label {
  opacity: 1;
  transform: scale(0.9) translateY(-110%) translateX(-10px);
  color: black;
}
.Zoundslike_word_input_synr input:focus {
  border: 1px solid black;
  outline-color: black;
}
.Zoundslike_word_input_synr {
  margin: 10px 0px;
}
.Zoundslike_word_input_synr input {
  width: 100%;
  padding-left: 10px;
  border-radius: 6px;
  border: 1px solid black;
}
.Zoundslike_word_input_synr label,
.Zoundslike_word_input_synr input {
  transition: all 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  margin-top: 15px;
}

.Zoundslike_input_label_synr {
  opacity: 0.5;
}

/* -------------------------------------------------------- */

.zoundslike_add_word_btn_synr {
  background-color: #4ba3c3;
  border: none;
  padding: 5px 10px;
  border-radius: 6px;
  color: #fff;
}

.zoundslike_add_word_cancle_btn_synr {
  margin-top: 20px;
}

.zoundslike_cancel_btn_synr {
  background-color: #4ba3c3;
  border: none;
  padding: 5px 10px;
  border-radius: 6px;
  margin-left: 20px;
  color: #fff;
}

/***** Admin user popup model start*****/
/**popup Model CSS Start**/
.model-div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.7) !important;
  /* background: #ebedef; */
  z-index: 99;
}

.model-div .modal {
  /***** 24-07-2023 bug no.43*****/
  /* padding: 35px 20px; */
  padding: 20px;
  z-index: 1;
  width: 700px;
  height: 240px;
  background: white;
  display: flow-root;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  animation: animate 0.3s;
}

.modal .popup-close {
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 8px 11px;
  background: crimson;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

.popup-close-btn {
  background: linear-gradient(180deg, #191919 0%, #42424a 100%);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 8px 25px;
  margin: 10px 0px;
  color: #fff;
  border: none;
}

.popup-text-div {
  padding: 40px;
}
.popup-text-div p {
  padding-bottom: 2px;
}
.model-popup-btn-group {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

.data-update-gif {
  width: 140px;
}

.insert-popup .modal {
  width: 400px !important;
  height: 370px !important;
  overflow: hidden;
}

.px-0.use_success_msg {
  color: #36d46b;
  white-space: pre;
}

.success-msg-row {
  margin: auto;
}

.delete-popup .modal {
  height: 50vh;
}

.delete-popup .delete-icon {
  width: 50px;
  height: 50px;
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

/**popup Model CSS End**/

/**popup cancel Model button CSS start**/
.model-popup-btn-group {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

.top-button {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  border: none;
  background-color: #000000;
  padding: 12px 24px;
  border-radius: 8px;
  margin-right: 8px;
  white-space: nowrap;
  line-height: 1rem;
}

.top-button:hover {
  background-color: #000;
  color: #fff;
}

.top-button:active {
  color: #fff !important;
  background-color: #000 !important;
  box-shadow: none;
}

.top-button button {
  background-color: transparent;
  border: none;
  font: inherit;
  padding: 0px;
}

/**popup cancel Model button CSS end**/

/***** Admin user popup model end*****/

/***** pagination rowsperpage text remove CSS Start*****/
.css-pdct74-MuiTablePagination-selectLabel{
  display: none !important;
}
.css-16c50h-MuiInputBase-root-MuiTablePagination-select{
  display: none !important; 
}
/***** pagination rowsperpage text remove CSS End*****/

/***** lis of words box shadow CSS Start*****/
.css-1fw5qro-MuiPaper-root{
  box-shadow: none !important;
}
.userPagePaper{
  box-shadow: none !important;
}
/***** lis of words box shadow CSS End*****/

.accordion-button::after{
  background-image: url('../../assets/icons/dropdownArrow.png') !important;
}

.zoundslike_result_dis_grid{
  display: grid !important;
}

@media only screen and (max-width: 767px){
  .top-button {
    font-size: 14px;
    padding: 10px 14px;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .top-button {
    font-size: 14px;
    padding: 10px 14px;
  }
}
