.zoundslike_login_container {
  background: linear-gradient(90deg, #021b79, #0575e6);
  height: 100vh;
  /* background-color: #021b79; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.zoundslike_login_div {
  width: 40%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}

.zoundslike_login_login_heading {
  text-align: center;
  margin: 0px;
}

.zoundslike_input_user_icon {
  position: relative;
  top: 23px;
  left: 5px;
  padding: 0 15px 0 0;
  display: flex;
  justify-content: space-between;
}

.zoundslike_input_group {
  position: relative;
  margin: 15px 0px;
}
.zoundslike_input_group label {
  position: absolute;
  padding-left: 10px;
  top: 19px;
  left: 17px;
  cursor: text;
}
.zoundslike_input_group input:focus + label,
.zoundslike_input_group input:not(:placeholder-shown) + label {
  opacity: 1;
  transform: scale(0.9) translateY(-150%) translateX(-29px);
  color: #000;
}
.zoundslike_input_group input {
  border: 1px solid #000;
  outline-color: #000;
}
.zoundslike_input_group input {
  width: 100%;
  padding-left: 30px;
  height: 30px;
}
.zoundslike_input_group label,
.zoundslike_input_group input {
  transition: all 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.50;
  border-radius: 5px;
}

.zoundslike_user_input:focus{
  opacity: 1;
}

.zoundslike_login_btn{
  margin-top: 10px;
  width: 13%;
  background: #175676;
  color: white;
  font-size: 15px;
  border: none;
  height: 35px;
  border-radius: 6px;
}
