.zoundslike_catagory_game_with_count_container {
  /* display: grid;
    grid-template-columns: repeat(6, 1fr); */
  /* margin-top: 20px; */
}

.zoundslike_catagory_game_with_count {
  text-align: center;
    padding: 30px 30px;
    background-color: white;
    margin: 10px 0;
    padding: 0px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.zoundslike_catagory_game_with_count_ {
  margin: 7px;
  padding: 5px;
}

.zoundslike_catagory_name_count_snr {
  text-align: center;
  margin-bottom: 0px
}

.zoundslike_loading_syn {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  animation: rotate 1s infinite linear;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
}


.zoundslikeBtn_loading_syn {
  height: 20px;
  width: 20px;
  padding: 0px;
  border: 2px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  animation: rotate 1s infinite linear;
  position: relative;
}


@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}



.game_searchBar{
  width: 50%;
  position: relative;
}

.game_searchBar input {
  width: 100%;
  padding: 8px 40px;
  border-radius: 4px;
  border: 1px solid black;
}

.game_searchBar span{
  position: absolute;
  left: 0;
  padding: 8px 10px;
}

@media (max-width: 678px){
  .game_searchBar{
    width: 100%;
  }
}


.games_difficulty_select_box {
  flex: 1;
}

.games_difficulty_select_box select{
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  background-color: transparent;
}

.games_difficulty_select_box select { 
  min-width: 100px;
}


.zoundslike_games_from_date_and_to_date_syn {
  /* width: 100%; */
  flex: 1 1;
  min-width: 150px;
  display: flex;
  /* text-align: end; */
  justify-content: flex-start;
  position: relative;
}

.zoundslike_games_date_filter_syn {
  width: 100%;
  /* width: 200px; */
  position: relative;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
}

.dropdown-games-to-from-calender-container {
  position: absolute;
  /* width: 230px; */
  margin-top: 45px;
  z-index: 99;
}

.loaderSection{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapperloader {
  width: 200px;
  height: 60px;
  position: relative;
  z-index: 1;
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #4BA3C3;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 .5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: .2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(66, 83, 94, 0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 .5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: .2s
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.react-calendar__tile--now{
  background: #3498db;
}
.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus{
  background: #3498dbad;
}