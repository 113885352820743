.zoundslike_user_bar_chart_date_filter_syn {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.zoundslike_user_bar_chart_from_date_and_to_date_syn {
  width: 35.6%;
  display: flex;
}
.zoundslike_user_page_from_date_and_to_date_syn {
  /* width: 35.6%; */
  flex: 1;
  /* display: flex; */
  min-width: 200px;
}

.zoundslike_user_page_date_filter_syn {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.getUsers_gender_select_box,
.getUsers_education_select_box,
.getUsers_country_select_box {
  flex: 1;
}

.getUsers_gender_select_box select,
.getUsers_education_select_box select,
.getUsers_country_select_box select{
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  background-color: transparent;
}

.getUsers_gender_select_box select,
.getUsers_country_select_box { 
  min-width: 100px;
}

.getUsers_education_select_box select { 
  min-width: 150px;
}

.user_page_user_apply_btn_section{
  display: 'flex';
  gap: '8px';
  margin-bottom: '10px';
  justify-content: 'space-between';
  align-items: 'center';
}

.user_page_searchBar{
  width: 30%;
  position: relative;
}

.user_page_searchBar input {
  width: 100%;
  padding: 8px 40px;
  border-radius: 4px;
  border: 1px solid black;
}

.user_page_searchBar span{
  position: absolute;
  left: 0;
  padding: 8px 10px;
}

@media (max-width: 678px){
  .user_page_searchBar{
    width: 100%;
  }
  .user_page_user_apply_btn_section{
    flex-wrap: wrap-reverse;
  }
}

/* .zoundslike_user_bar_chart_from_date_and_to_date_syn{
    width: 100%;
} */

.zoundslike_user_bar_chart_from_date_and_to_date_filter_syn {
  display: flex;
  width: 100%;
  /* justify-content: center;     */
  /* align-items: flex-end; */
  /* padding-bottom: 28px; */
  /* margin: auto; */
}
.zoundslike_user_page_from_date_and_to_date_filter_syn {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  /* justify-content: center;     */
  /* align-items: flex-end; */
  /* padding-bottom: 28px; */
  /* margin: auto; */
}

.react-calendar {
  width: 286px !important;
  border: none !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.react-calendar__navigation {
  margin-bottom: 0px !important;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
}

button:disabled {
  color: rgba(16, 16, 16, 0.2) !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: rgba(16, 16, 16, 0.5) !important;
}

.react-calendar__viewContainer {
  right: 0;
  background: white;
}

.user-dropdown-calender-container {
  position: absolute;
  width: 230px;
  z-index: 3;
  margin-top: 30px;
}
.user-page-dropdown-calender-container {
  position: absolute;
  width: 230px;
  z-index: 3;
}

.react-calendar__navigation button {
  min-width: 32px !important;
}

.bar_chart_filters_syn div {
  padding: 2px;
}

.bar_chart_daily_week_month_year_filter_syn {
  cursor: pointer;
}

.react-calendar__tile {
  padding: 5px !important;
}

g rect {
  cursor: pointer !important;
}

.zoundslike_user_list_button_for_count_syn{
  background-color: #175676 !important;
  text-align: center !important;
  color: white !important;
  padding: 10px 20px !important;
  margin-bottom: 25px;
  border-radius: 8px;
  cursor: pointer;
}

/* .css-1fw5qro-MuiPaper-root{
  background: none !important;
} */
.userPagePaper{
  background: none !important;
}

.css-xn82ks-MuiTable-root{
  background: white !important;
}




/* --------------user.js---------------- */

.user_difficulty_select_box {
  flex: 1;
}

.user_difficulty_select_box select{
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  background-color: transparent;
}

.user_difficulty_select_box select { 
  min-width: 200px;
}
.loaderSection{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapperloader {
  width: 200px;
  height: 60px;
  position: relative;
  z-index: 1;
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #4BA3C3;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 .5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: .2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(66, 83, 94, 0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 .5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: .2s
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}
.react-calendar__tile--now{
  background: #3498db;
}
.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus{
  background: #3498dbad;
}