// Here you can add other styles
// body{
//     overflow-x: hidden;
//     overflow-y: hidden;
// }
ul.header-nav.ms-3 {
  display: none;
}
.sidebar.sidebar-fixed {
  background-color: #4ba3c3;
}
.sidebar-brand {
  background: transparent;
}
.sidebar-nav .nav-link {
  margin: 5px;
}
a.nav-link {
  color: #fff !important;
}

h6.total-user {
  background-color: #4ba3c3;
  width: fit-content;
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  cursor: pointer;
}
.mt-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem;
}
.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  pointer-events: visible;
  cursor: pointer;
}
.header-divider {
  display: none;
}


button.header-toggler.ps-1 {
  background-color: #4ba3c3;
  color: #fff !important;
  padding: 3px;
  width: 40px;
}
button.sidebar-toggler.d-none.d-lg-flex {
  display: none !important;
}
.sidebar-nav .nav-link.active {
  background: #fff;
  color: #000 !important;
}
.sidebar-nav .nav-link.active {
  background: #fff;
  color: #000 !important;
  margin: 5px;
  border-radius: 10px;
}
.sidebar-nav .nav-link.active svg {
  color: #000 !important;
}
.ms-auto a {
  color: #000 !important;
  text-decoration: none !important;
  font-size: 16px;
  line-height: 26px;
}
.header-divider {
  display: none;
}
.header.header-sticky {
  margin-bottom: 20px;
}
.bg-info {
  --cui-bg-opacity: 1;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 15px;
  padding: 15px;
  cursor: pointer;
  height: 8em;
  border: none;
  background-color: #175676 !important;
}
.bg-primary {
  border-radius: 15px;
  padding: 30px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: pointer;
}
.col-lg-3 a {
  text-decoration: none !important;
}
.category-main-sec {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #000;
  line-height: 40px;
}
.zoundslike_catagorylist {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
h3.zoundslike_catagory_name_count_snr {
  font-size: 18px;
  line-height: 28px;
}
h3.zoundslike_catagory_name_count_snr.category-title {
  font-size: 30px;
  line-height: 40px;
  margin-top: 30px;
}

/***** pagination alignment css start*****/
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  top: calc(33% - 0.5em) !important;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  padding-bottom: 10px !important;
}
.css-xn82ks-MuiTable-root {
  padding: 15px;
}
p.MuiTablePagination-displayedRows {
  margin-bottom: 0px;
}
.MuiButtonBase-root {
  background-color: #4ba3c3 !important;
  margin: 5px !important;
  color: #fff !important;
}
/***** pagination alignment css end*****/
.modal-footer {
  border: none;
}
.modal-header {
  border: none;
}
.accordion-button {
  background-color: #4ba3c3 !important;
  color: #fff !important;
}
.accordion-button:focus {
  z-index: 3;
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.css-1fw5qro-MuiPaper-root{
  box-shadow: none;
}

.spinner-border{
  display: none;
}

button.header-toggler.ps-1 {
  display: none;
}
.body.flex-grow-1.px-3 {
  margin-top: 20px;
}
.header.header-sticky {
  margin-bottom: 20px;
  display: none;
}
.mt-5 {
  margin-top: 0px !important;
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 40px;
  border-radius: 4px;
}

// table css strat
.userid {
  width: 6%;
}
.username {
  width: 13%;
} 
.useremail {
  width: 20%;
}
.userrole {
  width: 10%;
}
.usercreatedate {
  width: 14%;
}
.usercreateddate{
  width: 14%;
}
.css-1chpzqh{
  margin-bottom: 2px;
}

// --------table rows css-------
div.MuiTablePagination-toolbar.MuiToolbar-gutters.MuiToolbar-regular.MuiToolbar-root.css-8nphli{
  p.MuiTablePagination-selectLabel.css-1chpzqh{
    display: none;
  }
  div.MuiInputBase-root.MuiInputBase-colorPrimary.css-rmmij8{
    display: none;
    input.MuiSelect-nativeInput.css-1k3x8v3{
      display: none;
    }
    div.MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input.css-1cccqvr{
      display: none;
    }
  }
}

div.sidebar-brand.d-none.d-md-flex{
  margin-top: 14px;
  margin-bottom: 10px;
}

.zoundslike_copyright_webby_footer_synr{
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
.zoundslike_admin_words_crud_syn {
  width: 100% !important;
  padding: 20px 5px I !important; 
}
.ms-auto {
  text-align: center;
  margin: auto;
}
.footer {
  display: unset;
}
span.ms-1 {
  text-align: center;
  margin: auto;
  display: block;
}
button.header-toggler.ps-1 {
  display: block !important;
}

.header.header-sticky {
  margin-bottom: 0px !important;
  display: block !important;
}
}

